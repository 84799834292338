import React from 'react';
import Hero1 from '../images/hero1.svg';
import Hero2 from '../images/hero2.svg';

export default function Example() {
  return (
    <div className="relative pt-8 pb-32 overflow-hidden">
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <Hero1
                className="w-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none overflow-visible"
                alt="Customer profile user interface"
              />
            </div>
          </div>
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-1">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-black">
                  Use html templates, with custom CSS and font
                </h2>
                <p className="mt-4 text-lg text-gray-700">
                  Template builders are nice. But what if you could get the full power of html and CSS? With mindthedoc you can generate pixel-perfect PDF.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-black">
                  API powered
                </h2>
                <p className="mt-4 text-lg text-gray-700">
                  Whether is 1 or 1000, you can use our API to generate your PDFs.
                  Or use our integrations with tools like Zapier, Make or Airtable.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <Hero2
                className="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none overflow-visible"
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
