import React from 'react';

import { Link } from 'gatsby';
import { siteMap } from '../utils/sitemap';

function Hero({ title, description, cta }) {
  return (
    <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0">
          <div className="lg:py-24">
            {title}
            <p className="text-base text-black sm:text-xl lg:text-lg xl:text-xl">
              {description}
            </p>
            {cta && (
              <div className="mt-5 mx-auto sm:flex md:mt-8">
                <div className="rounded-md shadow mx-auto">
                  <Link
                    to={siteMap.SIGN_UP.link}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-tb-400 hover:bg-blue-tb-200 md:py-4 md:text-lg md:px-10"
                  >
                    Start for free
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
