import React from 'react';
import {
  DocumentDuplicateIcon,
  CurrencyEuroIcon,
  ShieldCheckIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline';
import Hero from '../components/hero';
import Feature from '../components/feature';
import Feature3 from '../components/feature3';
import CTA from '../components/cta';
import Layout from '../components/layout';
import Seo from '../components/seo';

const features = [
  {
    name: 'Public & Private shareable link',
    description:
      'When you generate a document you can get a public link to the PDF or a private link that expires after a certain amount of time.',
    icon: DocumentDuplicateIcon,
  },
  {
    name: 'HTML & CSS support',
    description:
      'No limits to craft the most beautiful PDFs. Use custom CSS, framework or even bring your own font.',
    icon: CurrencyEuroIcon,
  },
  {
    name: 'Privacy and security first',
    description:
      'Our platform is hosted in the European Union and we comply with all the privacy and security regulations.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Easy to integrate',
    description:
      'With our open APIs you can build a custom integration with your app or use no/low-code platforms to generate PDFs from spreadsheets or rows.',
    icon: DocumentTextIcon,
  },
];

function IndexPage({ location }) {
  return (
    <Layout>
      <Seo title="Powerful API for PDF documents generation" path={location.pathname} />
      <Hero
        title={
          <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
            <span className="block">Generate hundreds of </span>
            <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-blue-tb-400 bg-purple-tb-100 via-orange-tb-400 sm:pb-5">
              PDF documents
            </span>
          </h1>
        }
        description="With our APIs you can generate pixel-perfect PDF documents from templates."
        cta
      />
      <Feature />
      <Feature3
        // title="Pay and getting paid: Done."
        // description="We make pay your suppliers and getting paid from your customers as easy as possible. You can track your suppliers' invoices, receive reminders and be always on top of collecting your invoices from your customers right on time."
        features={features}
      />
      <CTA />
    </Layout>
  );
}

export default IndexPage;
